
import { defineComponent, ref, computed, onMounted, watch } from 'vue'
import { useRouter, useRoute, onBeforeRouteUpdate, LocationQuery } from 'vue-router'
import { useStore } from 'vuex'
import AppSelect from '@/components/atoms/AppSelect.vue'
import AppButton from '@/components/atoms/AppButton.vue'
import Table from '@/components/organisms/dms/common/Table.vue'
import PageNavigation from '@/components/molecules/PageNavigation.vue'
import KeywordSearch from '@/components/molecules/KeywordSearch.vue'
import DssIcon from '@/components/atoms/DssIcon.vue'
import { postDataCredential, postDataBlob } from '@/helpers/util/webApiUtil'
import { displayList } from '@/data/selector/displayList'
import { useI18n } from 'vue-i18n'
import { getSelector } from '@/data/selector/getSelector'

const BASE_URL = process.env.VUE_APP_DMS_API_BASE_URL

export default defineComponent({
  name: 'Result',
  components: {
    AppSelect,
    AppButton,
    Table,
    PageNavigation,
    KeywordSearch,
    DssIcon,
  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const selected = ref(route.query?.ndc || '')
    const keyword = ref(route.query?.keyword || '')
    const body = ref()
    const i18n = useI18n()
    const selectList = [
      { value: '', name: 'NDC分類' },
      { value: '0', name: '0類 総記' },
      { value: '1', name: '1類 哲学' },
      { value: '2', name: '2類 歴史' },
      { value: '3', name: '3類 社会科学' },
      { value: '4', name: '4類 自然科学' },
      { value: '5', name: '5類 技術' },
      { value: '6', name: '6類 産業' },
      { value: '7', name: '7類 芸術' },
      { value: '8', name: '8類 言語' },
      { value: '9', name: '9類 文学' },
    ]
    const numSelected = ref(Number(route.query?.size) || 20)
    const sortSelected = ref(route.query?.sortOrder || 'asc')
    const displayLocale = computed(() => getSelector('displayList', displayList, i18n.t))
    const sortList = [
      { name: '著者名：昇順', value: 'asc' },
      { name: '著者名：降順', value: 'desc' },
    ]
    const header = ref([
      { key: 'no', name: 'No', width: '7%' },
      { key: 'authorId', name: '著者ID', width: '14%' },
      { key: 'authorName', name: '著者名', width: '14%' },
      { key: 'authorAliasName', name: '著者別名', width: '14%' },
      { key: 'birthYear', name: '生年', width: '9%' },
      { key: 'deathYear', name: '没年', width: '9%' },
      { key: 'ndc', name: '著作NDC分類', width: '14%' },
      { key: 'count', name: '著作件数', width: '8%' },
      { key: 'input', name: '', width: '11%' },
    ])

    const result = ref([])
    const pageNumber = computed({
      get: () => Number(route.query.page ? route.query.page : 0),
      set: (val) => {
        router.push({
          query: {
            ...route.query,
            page: (val).toString(),
            size: numSelected.value,
          },
        })
      },
    })

    const pageTotalCount = ref(0)

    const pageNavigationIndex = computed(() => {
      const pageCount = Math.ceil(result.value.length !== 0 ? pageTotalCount.value / (route.query.size ? Number(route.query.size) : 20) : 0)
      return Array(pageCount).fill(0).map((_, i) => (i + 1).toString())
    })

    const pushState = () => {
      router.push({
        path: route.path,
        query: {
          keyword: keyword.value,
          ndc: selected.value,
          authorId: '',
          page: 0,
          size: numSelected.value,
          sortKey: 'authorName',
          sortOrder: sortSelected.value,
        },
        force: true,
      })
    }

    const doSearch = async (q: LocationQuery) => {
      await store.dispatch('setOpeninqSearch', q) // 「検索結果に戻る」で検索状態を復元するため保持
      const url = `${BASE_URL}/outer/inquiry/_search`
      body.value = JSON.stringify({
        authorId: q.authorId ? q.authorId : '',
        from: q.page ? String(Number(q.page) * Number(q.size)) : '0',
        keyword: q.keyword ? q.keyword : '',
        ndc: q.ndc ? q.ndc : '',
        size: q.size ? q.size : '20',
        sortKey: q.sortKey ? q.sortKey : 'authorName',
        sortOrder: q.sortOrder ? q.sortOrder : 'asc',
      })
      const response = await postDataCredential(url, body.value, true)
      result.value = response.data.hits.hits
      pageTotalCount.value = response.data.hits.total.value
    }

    const resetCollectAuthorData = async () => {
      await store.dispatch('setCollectAuthorData', {})
    }

    onBeforeRouteUpdate(async (to, _) => {
      if (to.fullPath === '/openinq/search') {
        keyword.value = ''
        selected.value = ''
        numSelected.value = 20
        sortSelected.value = 'asc'
      }
      await doSearch(to.query)
    })
    onMounted(async () => {
      await doSearch(route.query)
    })
    watch(numSelected, () => {
      pushState()
    })
    watch(sortSelected, () => {
      pushState()
    })

    return {
      router,
      route,
      selected,
      keyword,
      selectList,
      numSelected,
      sortSelected,
      displayLocale,
      sortList,
      header,
      result,
      pageTotalCount,
      pageNumber,
      pageNavigationIndex,
      pushState,

      async search () {
        await doSearch(route.query)
      },
      convertNdc (ndc: any) {
        const list = []
        for (var item of ndc) {
          const oneNdc = item === '0' ? '0類 総記'
            : item === '1' ? '1類 哲学'
              : item === '2' ? '2類 歴史'
                : item === '3' ? '3類 社会科学'
                  : item === '4' ? '4類 自然科学'
                    : item === '5' ? '5類 技術'
                      : item === '6' ? '6類 産業'
                        : item === '7' ? '7類 芸術'
                          : item === '8' ? '8類 言語'
                            : item === '9' ? '9類 文学' : ''
          list.push(oneNdc)
        }
        return list
      },
      async detail (authorId: string) {
        await router.push('/dms/author/detail?authorId=' + authorId)
      },
      async toAuthorInput (authorId: string) {
        await resetCollectAuthorData()
        await router.push('/dms/author/input?authorId=' + authorId)
      },
      async download () {
        const url = `${BASE_URL}/outer/inquiry/download`
        const response = await postDataBlob(url, body.value)
        if (response.status === 200) {
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(response.data)
          link.download = '公開調査著作者リスト.xlsx'
          link.click()
        }
      },
    }
  },
})
