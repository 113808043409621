
import { defineComponent } from 'vue'
import RequestAbout from '@/components/organisms/dms/PublicSearchResult/RequestAbout.vue'
import Result from '@/components/organisms/dms/PublicSearchResult/Result.vue'
import SideMenu from '@/components/organisms/dms/common/SideMenu.vue'

export default defineComponent({
  name: 'PublicSearchResult',
  components: {
    RequestAbout,
    Result,
    SideMenu,
  },
})
