import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c715da2c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search-result-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RequestAbout = _resolveComponent("RequestAbout")!
  const _component_Result = _resolveComponent("Result")!
  const _component_SideMenu = _resolveComponent("SideMenu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_RequestAbout),
      _createVNode(_component_Result)
    ]),
    _createVNode(_component_SideMenu)
  ]))
}